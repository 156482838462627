import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import {
  // Router,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import SignupLogin from "views/SignupLogin/Signuplogin.js";
// import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

// const hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter
  // history={hist}
  >
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/login" component={SignupLogin} />
      {/* <Route path="/rtl" component={RTL} /> */}
      <Redirect from="/" to="/login" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
