import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DataService from "variables/data.js";
import { Alert } from "@material-ui/lab";

// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";

import homebanner from "assets/img/home-banner.jpg";
import logo from "assets/img/logo.png";

import Copyright from "../General/Copyright";
// import { Link as RouterLink } from "react-router-dom";
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${homebanner})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appbar: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoStyle: {
    width: "200px",
    height: "50px",
    margin: theme.spacing(3, 2, 2),
  },
}));

export default function SignUpLogin() {
  const classes = useStyles();
  let data = new DataService();
  let history = useHistory();

  const [message, setMessage] = React.useState("");

  let [form, setForm] = React.useState({
    email: "",
    password: "",
  });
  let [formerr, setFormerr] = React.useState({
    emailerr: "",
    passworderr: "",
  });

  const [submitted, setSubmit] = React.useState(false);

  const handleSubmit = () => {
    if (form.email === "") {
      setFormerr({ emailerr: "Email Required" });
      return;
    }

    if (form.password === "") {
      setFormerr({ passworderr: "Password Required" });
      return;
    }

    data.login(form).then((result) => {
      if (result.message === "User authenticated") {
        console.log("success");
        history.push("/admin");
      } else {
        setMessage(result.message);
        return;
      }
    });
  };

  const handleChange = (e) => {
    // let name = e.target.name;
    let value = e.target.value;
    form[e.target.name] = value;
    setForm(form);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        {/* <AppBar position="static">
          <Toolbar variant="dense" > */}
        <img src={logo} className={classes.logoStyle} alt="logo" />
        {/* </Toolbar>
        </AppBar> */}
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {/* <ValidatorForm
            ref="form"
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
          > */}
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              required
              fullWidth
              id="email"
              label="User Id"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={formerr.emailerr}
              // label="Email"
              // onChange={handleChange}
              // name="email"
              // value={form.email}
              // validators={["required", "isEmail"]}
              // errorMessages={["Email is required", "email is not valid"]}
            />
            <TextField
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              helperText={formerr.passworderr}
              autoComplete="current-password"
              // label="Password"
              // onChange={handleChange}
              // name="password"
              // value={form.password}
              // validators={["required"]}
              // errorMessages={["Password is required"]}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            {message === "Account Doesn't Exist" ? (
              <Alert severity="error">{message}</Alert>
            ) : (
              ""
            )}
            {message === "Your Account Locked." ? (
              <Alert severity="warning">{message}</Alert>
            ) : (
              ""
            )}

            <Button
              // component={RouterLink}
              onClick={handleSubmit}
              type="button"
              fullWidth
              variant="contained"
              disabled={submitted}
              // color="primary"
              className={classes.submit}
              style={{ backgroundColor: "#4caf50", color: "white" }}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            <Box mt={5}>
              <Copyright />
            </Box>
            {/* </ValidatorForm> */}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
