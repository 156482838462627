import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/avator2.png";
// import { Person } from "@material-ui/icons";
// import Success from "components/Typography/Success";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <img
                style={{
                  backgroundColor: "#FFF",
                }}
                src={avatar}
                alt="..."
              />
              {/* <Success>
                <Person
                  style={{
                    width: 120,
                    height: 120,
                    backgroundColor: "#FFF",
                  }}
                />
              </Success> */}
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>Administrator</h6>
              <h4 className={classes.cardTitle}>Arun Kumar</h4>
              <p className={classes.description}>
                We, “THE INDCOSERVE” (The Tamilnadu Small Tea Growers’
                Industrial Cooperative Tea Factories’ Federation Limited,
                IND-697) is the largest Tea Co-operative Federation in India,
                with 16 modern Industrial Cooperative Tea Factories and 25,000
                Small Tea Growers as its members.
              </p>
              {/* <Button color="success" round>
                Edit
              </Button> */}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Company (disabled)"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={6}>
                  {/* <CustomInput
                    labelText="Username"
                    id="username"
                    Value="Indcoserve"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}
                  <TextField
                    required
                    id="username"
                    label="Username"
                    defaultValue="IndcoserveTest@indcoserve.com"
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="Destination"
                    label="Destination"
                    defaultValue="Administrator"
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="First Name"
                    label="FirstName"
                    defaultValue="Arun"
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="Last-Name"
                    label="Last Name"
                    defaultValue="Kumar"
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="Email"
                    label="email"
                    defaultValue="info@indcoserve.com"
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="Mobile No"
                    label="mobile"
                    defaultValue="94430 20429"
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required
                    id="Address"
                    label="Address"
                    defaultValue="No.35, Church Road,
                    Coonoor – 643 101,
                    The Nilgiris – Tamilnadu – INDIA."
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="success">Update Profile</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
