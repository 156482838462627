import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";

import { BusinessCenter, People, VerifiedUser } from "@material-ui/icons";
// import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import TasksMasters from "components/Tasks/TasksMasters.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";

import {
  // member,
  members,
  factory,
} from "variables/general.js";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// const useStyles = makeStyles(styles);

export default function Master() {
  //   const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            // title="Notifications:"
            headerColor="success"
            tabs={[
              {
                tabName: "Members",
                tabIcon: People,
                tabContent: <TasksMasters tasks={members} title={"Members"} />,
              },
              {
                tabName: "Factorys",
                tabIcon: BusinessCenter,
                tabContent: <TasksMasters tasks={factory} title={"Factorys"} />,
              },
              {
                tabName: "Users",
                tabIcon: VerifiedUser,
                tabContent: <TasksMasters tasks={members} title={"Users"} />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
