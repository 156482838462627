import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import xlsx from "assets/files/members-supplies.xlsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <h4 className={classes.cardTitleWhite}>Tea Supplied</h4>
              </Grid>
              {/* <Grid item xs={2}>
                <Link href={xlsx} color="inherit">
                  Upload Today Supplies
                </Link>
              </Grid> */}
            </Grid>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="success"
              tableHead={[
                "S.No",
                "Members Name",
                "Arrival of Leaf (in Kg)",
                "Quality Rejection (in Kg)",
                "Price Per Kg (₹ in Rs)",
              ]}
              tableData={[
                ["1", "	ABINAYA TEA PRODUCE CO. P. LTD.	", "230", "20", "130.00"],
                ["2", "	AKSHAYA TEA INDUSTRIES.	", "100", "0", "100.00"],
                ["3", "	AMKO TEA PRIVATE LIMITED.	", "234", "0", "130.00"],
                ["4", "	ARTHIBALA TEA PLANTATIONS.	", "45", "0", "130.00"],
                ["5", "	ASHOK KUMAR TEA FACTORY.	", "689", "0", "130.00"],
                ["6", "	ATTIKUNNA TEA FACTORY	", "400", "21", "130.00"],
                ["7", "	ALLADAVALLEY TEA FACTORY	", "245", "5", "130.00"],
                ["8", "	AKKAMALAI ESTATE	", "500", "0", "130.00"],
                ["9", "	ALWIN TEA INDUSTRIES	", "400", "0", "130.00"],
                ["10", "	AVATAA BEVERAGES.	", "521", "0", "130.00"],
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
