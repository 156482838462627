import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import { Update } from "@material-ui/icons";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
// import CardIcon from "components/Card/CardIcon.js";
// import GradeIcon from "@material-ui/icons/Grade";
// import {
//   bugs,
//   website,
//   websiteDate,
//   website2,
//   websiteDate2,
//   bugsDate,
//   website1,
//   // server
//   websiteDate1,
// } from "variables/general.js";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

import DataService from "variables/data.js";
// import Api from "services/api.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function ContantManagement() {
  const classes = useStyles();

  let [notification, setNotification] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  let data = new DataService();

  React.useEffect(() => {
    data
      .getNotification()
      // .then((res) => res.json())
      .then((result) => {
        setNotification(result);
      });
  }, []);

  let AboutData = notification.filter((val) => {
    return val.ntype === "About";
  });

  // });

  const handleReRender = () => {
    data
      .getNotification()
      // .then((res) => res.json())
      .then((result) => {
        setNotification(result);
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            // title="Notifications:"
            headerColor="success"
            tabs={[
              {
                tabName: "About",
                tabIcon: Update,
                tabContent: (
                  <Tasks
                    // checkedIndexes={[0, 3]}
                    // tasksIndexes={[0, 1, 2, 3]}
                    tasks={AboutData}
                    taskshandle={handleReRender}
                    title={"About"}
                  />
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Indcoserve Tea Price</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={"Amount"}
            rows={2}
            type="text"
            fullWidth
          />

          <TextField
            id="date"
            label="Date"
            type="date"
            defaultValue="2020-05-23"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
