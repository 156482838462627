// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  "Additional circular in respect to Special Tea Plucking and Manufacture and special auction in commemoration of the International Tea day on 21st May, 2020",
  "Revised dates for submission of returns",
  "Circular in regard to increase in efficiency in auction operations.",
  "Standard Operating Procedure guidelines for Tea Sector during COVID-19 Pandemic",
];
var bugsDate = ["21 May, 2020", "10 May, 2020", "1 May, 2020", "3 Feb, 2020"];
var website = ["Revised Notice to stakeholders"];
var websiteDate = ["21 May, 2020"];

var website1 = [
  "Circular in regard to increase in efficiency in auction operations.",
];
var websiteDate1 = ["21 May, 2020"];
var website2 = [
  "Tea leaves begin to wilt soon after  plucking, with a gradual beginning of enzymatic  oxidation. This process is called withering, and is  used to eliminate excess water from the leaves and  allow slight oxidation. Cold or warm air is blown  through the leaf for 12 to 18 hours..",
  "Teas are bruised or torn in order to promote and accelerate oxidation. The bruising breaks down the structures inside and outside of the leaf cells and allows, from the co-mingling of oxidative enzymes with various substrates, which allows the beginning of oxidation",
];
var websiteDate2 = ["21 May, 2020", "20 May, 2020"];
// var server = [
//   "Standard Operating Procedure guidelines for Tea Sector during COVID-19 Pandemic",
//   "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
//   'Sign contract for "What are conference organizers afraid of?"'
// ];

var members = [
  ["1", "	ABINAYA TEA PRODUCE CO. P. LTD.	", "Seller"],
  ["2", "	AKSHAYA TEA INDUSTRIES.	", "Seller"],
  ["3", "	AMKO TEA PRIVATE LIMITED.	", "Seller"],
  ["4", "	ARTHIBALA TEA PLANTATIONS.	", "Seller"],
  ["5", "	ASHOK KUMAR TEA FACTORY.	", "Seller"],
  ["6", "	ATTIKUNNA TEA FACTORY	", "Seller"],
  ["7", "	ALLADAVALLEY TEA FACTORY	", "Seller"],
  ["8", "	AKKAMALAI ESTATE	", "Seller"],
  ["9", "	ALWIN TEA INDUSTRIES	", "Seller"],
  ["10", "	AVATAA BEVERAGES.	", "Seller"],
  ["11", "	BANACOMBAI TEA PLANTATIONS P. LTD.	", "Seller"],
  ["12", "	BEEKAAD TEA PRODUCING CO. P. LTD.	", "Seller"],
  ["13", "	BENGALMATTAM (NILGIRI) ESTATES TEA FACTORY.	", "Seller"],
  ["14", "	BENGALVIEW (NILGIRIS) ESTATES TEA FACTORY.	", "Seller"],
  ["15", "	BENNKAL TEA FACTORY.	", "Seller"],
  ["16", "	BHARATHI TEA FACTORY.	", "Buyer"],
  ["17", "	BHAGAWAN TEA FACTORY	", "Buyer"],
  ["18", "	BELMA MICRO TEA.	", "Buyer"],
  ["19", "	BEEGEES MICRO TEA FACTORY.	", "Buyer"],
  ["20", "	BENGORM TEA FACTORY.	", "Buyer"],
  ["21", "	BELLATTI ESTATE TEA FACTORY.	", "Buyer"],
  ["22", "	CHAMBALA TEA FACTORY PVT. LTD.	", "Buyer"],
  ["23", "	CRAIGMORE PLANTATIONS (INDIA) PVT. LTD 	", "Buyer"],
  ["24", "	CROSS HILL ARAVENU TEA CO. PVT. LTD.	", "Buyer"],
  ["25", "	CHERAMBADY TEA FACTORY.	", "Buyer"],
  ["26", "	CHOKANAD TEA FACTORY.	", "Seller"],
  ["27", "	CHERANGODE TEA FACTORY.	", "Seller"],
  ["28", "	CHAMRAJ TEA FACTORY. LTD.	", "Seller"],
  ["29", "	CHERAKARA ESTATE TEA FACTORY.	", "Seller"],
  ["30", "	DARMONA TEA INDUSTRY	", "Seller"],
  ["31", "	DAVERSHOLA TEA FACTORY.	", "Seller"],
  ["32", "	DODDACOMBAU TEA FACTORY CO. P. LTD.	", "Seller"],
  ["33", "	DOLLAR TEA INDUSTRY PVT. LIMITED.	", "Seller"],
  ["34", "	DHANLAAKSHMI TEA CO	", "Seller"],
  ["35", "	DODDABETTA TEA FACTORY.	", "Seller"],
  ["36", "	ELAVANKUDY TEA COMPANY	", "Seller"],
  ["37", "	ELLA TEA INDUSTRY	", "Seller"],
  ["38", "	ELLITHORAI TEA FACTORY PVT. LTD.	", "Seller"],
  ["39", "	ESSEN TEA INDUSTRY	", "Seller"],
  ["40", "	EVELYN TEA FACTORY	", "Seller"],
  ["41", "	EVER GREEN TEA FACTORY	", "Seller"],
  ["42", "	ELSTONE TEA ESTATES LTD	", "Seller"],
  ["43", "	FIVE STAR TEA PRIVATE LTD.	", "Seller"],
  ["44", "	FORTUNE TEA INDUSTRY	", "Seller"],
  ["45", "	GARSWOOD TEA PRODUCE COMPANY	", "Seller"],
  ["46", "	GEEVEE TEA INDUSTRIES PVT. LTD.	", "Seller"],
  ["47", "	GLENWORTH ESTATE LIMITED	", "Seller"],
  ["48", "	GOLDEN DEW TEA FACTORY	", "Seller"],
  ["49", "	GOLDEN HILL TEA PLANTATIONS	", "Seller"],
  ["50", "	GOLDEN HILLS ESTATE PVT. LTD.	", "Seller"],
  ["51", "	GOTTACOMBAI TEA INDUSTRIES	", "Seller"],
  ["52", "	GREENVIEW TEA INDUSTRIES	", "Seller"],
  ["53", "	GREEN BUDS PROCESSING PVT LTD	", "Seller"],
  ["54", "	GAHANASHIRI TEA PRODUCE CO	", "Seller"],
  ["55", "	GREEN T ESTATE	", "Seller"],
  ["56", "	GREEN HILL TEA	", "Seller"],
  ["57", "	H.R.D. TEA INDUSTRIES	", "Seller"],
  ["58", "	HADATHORAI TEA FACTORY.	", "Seller"],
  ["59", "	HATARI TEA INDUSTRIES	", "Seller"],
  ["60", "	HAVUKAL TEA & PRODUCE CO. P. LTD.	", "Seller"],
  ["61", "	HIGH CLIFF TEA MANUFACTURING CO. P. LTD.	", "Seller"],
  ["62", "	HITTAKKAL ESTATE TEA FACTORY	", "Seller"],
  ["63", "	HOMEDALE TEA FACTORY.	", "Seller"],
  ["64", "	HUBBADA TEA PLANTATIONS P.LTD	", "Seller"],
  ["65", "	HIMALAYA TEA PLANTATIONS.	", "Seller"],
  ["66", "	IBS TEA PLANTATIONS PVT. LIMITED	", "Seller"],
  ["67", "	IBEX LODGE TEA FACTORY	", "Buyer"],
  ["68", "	JAE PPI AAR TEA INDUSTRIES	", "Buyer"],
  ["69", "	JEYAM TEA PLANTATIONS	", "Buyer"],
  ["70", "	KAIRBETTA ESTATE SYNDICATE	", "Buyer"],
  ["71", "	KANMALAI TEA INDUSTRY.	", "Seller"],
  ["72", "	KANNAVARAI TEA INDUSTRIES	", "Seller"],
  ["73", "	KARODAIYA TEA INDUSTRIES	", "Seller"],
  ["74", "	KIL-KOTAGIRI AND THIRUMBADY PLANTATIONS LTD.	", "Seller"],
  ["75", "	KOKKALATTY TEA INDUSTRY.	", "Seller"],
  ["76", "	KODANAD TEA ESTATE	", "Seller"],
  ["77", "	KOTAGIRI ESTATES TEA (A UNIT OF VGIL)	", "Seller"],
  ["78", "	KORAKUNDAH TEA FACTORY LTD	", "Seller"],
  ["79", "	KALLINGARA TEA FACTORY.	", "Seller"],
  ["80", "	KONAVAKORAI TEA PLANTATIONS	", "Seller"],
  ["81", "	MACWOOD TEA COMPANY.	", "Seller"],
  ["82", "	MADESWARA ESTATES TEA FACTORY P. LTD.	", "Seller"],
  ["83", "	MADHAMALAI TEA PLANTATIONS P. LTD.	", "Seller"],
  ["84", "	MAHARAJ TEA FACTORY PVT. LTD.	", "Seller"],
  ["85", "	MANIKAL TEA FACTORY	", "Seller"],
  ["86", "	MAHALINGA TEA FACTORY.	", "Seller"],
  ["87", "	MAVUKARAI (NILGIRI) EST. TEA FACTORY	", "Seller"],
  ["88", "	MEEDHANE TEA INDUSTRIES	", "Seller"],
  ["89", "	MOTHERLAND TEA PLANTATIONS P. LTD.	", "Seller"],
  ["90", "	MANJUBETTA TEA FACTORY.	", "Seller"],
  ["91", "	MADUPATTY TEA FACTORY	", "Seller"],
  ["92", "	MALLIAH TEA FACTORY.	", "Seller"],
  ["93", "	MALAKIPARAI TEA ESTATE	", "Seller"],
  ["94", "	MANJOLAI TEA FACTORY.	", "Seller"],
  ["95", "	NANKEMP TEA INDUSTRY	", "Seller"],
  ["96", "	NARIKKAL TEA FACTORY	", "Buyer"],
  ["97", "	NARUMUKAI TEA COMPANY	", "Buyer"],
  ["98", "	NATIONAL TEA PLANTATIONS P. LTD.	", "Buyer"],
  ["99", "	NEELAMALAI AGRO INDUSTRIES LTD.	", "Buyer"],
  ["100", "	NILGIRI AGRO AGENCIES PVT. LTD.	", "Seller"],
  ["101", "	NILGIRI GLENBURN TEA LIMITED	", "Seller"],
  ["102", "	NILGIRI MONARCH TEA LIMITED	", "Seller"],
  ["103", "	NONSUCH TEA ESTATES LIMITED	", "Seller"],
  ["104", "	NILGIRI TEA ESTATES LIMITED	", "Seller"],
  ["105", "	NILGIRI TEA PRODUCING COMPANY	", "Seller"],
  ["106", "	NIRSAN PLANTATIONS	", "Seller"],
  ["107", "	NITON TEA COMPANY	", "Seller"],
  ["108", "	NAVILKAL ESTATE TEA FACTORY	", "Seller"],
  ["109", "	NEELAMALAI ENTERPRISES P.LTD	", "Seller"],
  ["110", "	NILGIRI MG TEA INDUSTRY	", "Seller"],
  ["111", "	NELLIYALAM TEA ESTATES LTD	", "Seller"],
  ["112", "	ORANGE VALLEY TEA INDUSTRY	", "Seller"],
  ["113", "	PANDIAN TEA INDUSTRIES	", "Seller"],
  ["114", "	PINEHILL TEA PLANTATIONS	", "Seller"],
  ["115", "	PARK VALLEY TEA PLANTATIONS	", "Seller"],
  ["116", "	PODDAR PLANTATION	", "Seller"],
  ["117", "	POWSLAND TEA FACTORY	", "Seller"],
  ["118", "	PRAMS TEA INDUSTRIES	", "Buyer"],
  ["119", "	PREETHI TEA INDUSTRY PVT. LTD.	", "Buyer"],
  ["120", "	PONMALAI TEA FACTORY	", "Buyer"],
  ["121", "	PRIYA TEA INDUSTRIES.	", "Buyer"],
  ["122", "	PANDIAR TEA FACTORY	", "Seller"],
  ["123", "	QUINSHOLA TEA FACTORY.	", "Seller"],
  ["124", "	RAJA PLANTATIONS PVT. LIMITED	", "Seller"],
  ["125", "	RAJESWARI TEA INDUSTRIES.	", "Seller"],
  ["126", "	RASI TEA INDUSTRY.	", "Seller"],
  ["127", "	RICHMOUNT TEA INDUSTRIES.	", "Seller"],
  ["128", "	RIVERSIDE TEA PLANTATION	", "Seller"],
  ["129", "	ROCK VALLEY TEA INDUSTRY P. LTD.	", "Seller"],
  ["130", "	ROSE MOUNT TEA FACTORY PVT. LTD.	", "Seller"],
  ["131", "	RIVERWOODS TEA PLANTATIONS	", "Seller"],
  ["132", "	ROYAL HERITAGE TEA PLANTATIONS	", "Seller"],
  ["133", "	RAJAMALAI TEA FACTORY	", "Seller"],
  ["134", "	RYAN TEA FACTORY	", "Seller"],
  ["135", "	ROSE VALLEY TEA INDUSTRIES.	", "Seller"],
  ["136", "	ROBROY TEA FACTORY (GEEEMM TEA FACTORY)	", "Seller"],
  ["137", "	S. N. VALLEY TEA FACTORY	", "Seller"],
  ["138", "	SAMPATH TEA INDUSTRIES P. LTD.	", "Seller"],
  ["139", "	SANTOSH TEA INDUSTRIES P. LTD.	", "Seller"],
  ["140", "	SEETHAL TEA FACTORY.	", "Seller"],
  ["141", "	SELVAGANAPATHY TEA INDUSTRIES.	", "Seller"],
  ["142", "	SHANTHY TEA INDUSTRY.	", "Seller"],
  ["143", "	SHARON TEA INDUSTRY.	", "Seller"],
  ["144", "	SHYAMA SHYAM TEA INDUSTRY.	", "Seller"],
  ["145", "	SILVER CLOUD ESTATE P. LTD.	", "Seller"],
  ["146", "	SILVER VALLEY TEA INDUSTRIES	", "Seller"],
  ["147", "	SINGARA NILGIRI PLANTATION COMPANY.	", "Seller"],
  ["148", "	SIVA & SIVA TEA FACTORY	", "Seller"],
  ["149", "	SIVA TEA PLANTATIONS.	", "Seller"],
  ["150", "	SIVAKUMAR TEA INDUSTRIES	", "Seller"],
  ["151", "	SIVARAM TEA INDUSTRY.	", "Seller"],
  ["152", "	SNOWDON TEA FACTORY	", "Seller"],
  ["153", "	SOGATHORAI VANI VILAS EST. COMPANY.	", "Seller"],
  ["154", "	SOUTHERN TREE FARMS LIMITED	", "Seller"],
  ["155", "	SPRINGDALE COMPANY TEA FACTORY.	", "Seller"],
  ["156", "	SREE DEVI TEA FACTORY P. LIMITED	", "Seller"],
  ["157", "	SREERAM TEA FACTORY.	", "Seller"],
  ["158", "	SREE SAKTHI TEA INDUSTRY	", "Seller"],
  ["159", "	SREE SASTHA TEA FACTORY.	", "Seller"],
  ["160", "	SREE VENKATESWARA TEA INDUSTRIES	", "Seller"],
  ["161", "	SRI BATHMA TEA INDSTRIES.	", "Seller"],
  ["162", "	SRI IYYAN TEA INDUSTRIES.	", "Seller"],
  ["163", "	SRI JADAYALINGA TEA FACTORY.	", "Seller"],
  ["164", "	SRI SIVALINGA TEA INDUSTRIES.	", "Seller"],
  ["165", "	SS TEA PLANTATIONS.	", "Seller"],
  ["166", "	STANES AMALGAMATTED EST. PVT. LTD. (KOTADA)	", "Seller"],
  ["167", "	STERLING TEA FACTORY.	", "Seller"],
  ["168", "	SHOLAYAR TEA FACTORY	", "Seller"],
  ["169", "	SVT TEA FACTORY	", "Seller"],
  ["170", "	STERLING TEA FACTORY	", "Seller"],
  ["171", "	SWAMY & SWAMY PLANTATIONS P. LTD.	", "Seller"],
  ["172", "	SHARAVANA TEA INDUSTRIES.	", "Seller"],
  ["173", "	SRI SUTHARSAN TEA INDUSTRY.	", "Seller"],
  ["174", "	SUPRAJITH TEA FACTORY	", "Seller"],
  ["175", "	SMART TEA PLANTATIONS.	", "Seller"],
  ["176", "	SATHYAM TEA INDUSTRIES	", "Seller"],
  ["177", "	SUSSEX TEA FACTORY	", "Seller"],
  ["178", "	TALAYAR TEA COMPANY LTD.	", "Seller"],
  ["179", "	TC TEA & ENTERPRISES PVT. LTD.	", "Seller"],
  ["180", "	THB TEA PLANTATIONS PVT.LTD.	", "Seller"],
  ["181", "	THE BHAVANI TEA AND PRODUCE CO. LTD.	", "Seller"],
  ["182", "	THE GNANAM TEA PLANTATIONS P. LTD.	", "Seller"],
  ["183", "	THE PUTHUTOTAM ESTATES (1943) LTD.	", "Seller"],
  ["184", "	THE WEST SIDE TEA COMPANY.	", "Seller"],
  ["185", "	THIASHOLA PLANTATIONS PVT. LTD.	", "Seller"],
  ["186", "	TOP HILL TEA FACTORY	", "Seller"],
  ["187", "	TUTTAPULLAM TEA & PRODUCE CO.P.LTD	", "Seller"],
  ["188", "	TIGERHILL TEA FACTORY.	", "Seller"],
  ["189", "	TCS TEA INDUSTRIES.	", "Seller"],
  ["190", "	UNITED PLANTERS ASSOCIATION OF SOUTHERN INDIA	", "Seller"],
  ["191", "	VELMURUGAN TEA PLANTATIONS	", "Seller"],
  ["192", "	VENKATARAMANA TEA FACTORY P. LTD.	", "Seller"],
  ["193", "	VIGNESHWAR ESTATE TEA FACTORY	", "Seller"],
  ["194", "	VIJAYAKUMAR TEA INDUSTRIES	", "Seller"],
  ["195", "	VARUNI TEA COMPANY	", "Seller"],
  ["196", "	VENNIAR TEA FACTORY	", "Seller"],
  ["197", "	WOODBRIDGE TEA FACTORY	", "Seller"],
  ["198", "	WOODLAND TEA FACTORY.	", "Seller"],
  ["199", "	WENTWORTH ESTATES.	", "Seller"],
  ["200", "	YUVARAJ TEA MFG. COMPANY P. LTD.	", "Seller"],
  ["201", "	YASODHAA TEA INDUSTRY.	", "Seller"],
];

var member = [
  ["ABINAYA TEA PRODUCE CO. P. LTD."],
  ["AKSHAYA TEA INDUSTRIES."],
  ["AMKO TEA PRIVATE LIMITED."],
  ["ARTHIBALA TEA PLANTATIONS."],
  ["ASHOK KUMAR TEA FACTORY."],
  ["ATTIKUNNA TEA FACTORY"],
  ["ALLADAVALLEY TEA FACTORY"],
  ["AKKAMALAI ESTATE"],
  ["ALWIN TEA INDUSTRIES"],
  ["AVATAA BEVERAGES."],
  ["BANACOMBAI TEA PLANTATIONS P. LTD."],
  ["BEEKAAD TEA PRODUCING CO. P. LTD."],
  ["BENGALMATTAM (NILGIRI) ESTATES TEA FACTORY."],
  ["BENGALVIEW (NILGIRIS) ESTATES TEA FACTORY."],
  ["BENNKAL TEA FACTORY."],
  ["BHARATHI TEA FACTORY."],
  ["BHAGAWAN TEA FACTORY"],
  ["BELMA MICRO TEA."],
  ["BEEGEES MICRO TEA FACTORY."],
  ["BENGORM TEA FACTORY."],
  ["BELLATTI ESTATE TEA FACTORY."],
  ["CHAMBALA TEA FACTORY PVT. LTD."],
  ["CRAIGMORE PLANTATIONS (INDIA) PVT. LTD "],
  ["CROSS HILL ARAVENU TEA CO. PVT. LTD."],
  ["CHERAMBADY TEA FACTORY."],
  ["CHOKANAD TEA FACTORY."],
  ["CHERANGODE TEA FACTORY."],
  ["CHAMRAJ TEA FACTORY. LTD."],
  ["CHERAKARA ESTATE TEA FACTORY."],
  ["DARMONA TEA INDUSTRY"],
  ["DAVERSHOLA TEA FACTORY."],
  ["DODDACOMBAU TEA FACTORY CO. P. LTD."],
  ["DOLLAR TEA INDUSTRY PVT. LIMITED."],
  ["DHANLAAKSHMI TEA CO"],
  ["DODDABETTA TEA FACTORY."],
  ["ELAVANKUDY TEA COMPANY"],
  ["ELLA TEA INDUSTRY"],
  ["ELLITHORAI TEA FACTORY PVT. LTD."],
  ["ESSEN TEA INDUSTRY"],
  ["EVELYN TEA FACTORY"],
  ["EVER GREEN TEA FACTORY"],
  ["ELSTONE TEA ESTATES LTD"],
  ["FIVE STAR TEA PRIVATE LTD."],
  ["FORTUNE TEA INDUSTRY"],
  ["GARSWOOD TEA PRODUCE COMPANY"],
  ["GEEVEE TEA INDUSTRIES PVT. LTD."],
  ["GLENWORTH ESTATE LIMITED"],
  ["GOLDEN DEW TEA FACTORY"],
  ["GOLDEN HILL TEA PLANTATIONS"],
  ["GOLDEN HILLS ESTATE PVT. LTD."],
  ["GOTTACOMBAI TEA INDUSTRIES"],
  ["GREENVIEW TEA INDUSTRIES"],
  ["GREEN BUDS PROCESSING PVT LTD"],
  ["GAHANASHIRI TEA PRODUCE CO"],
  ["GREEN T ESTATE"],
  ["GREEN HILL TEA"],
  ["H.R.D. TEA INDUSTRIES"],
  ["HADATHORAI TEA FACTORY."],
  ["HATARI TEA INDUSTRIES"],
  ["HAVUKAL TEA & PRODUCE CO. P. LTD."],
  ["HIGH CLIFF TEA MANUFACTURING CO. P. LTD."],
  ["HITTAKKAL ESTATE TEA FACTORY"],
  ["HOMEDALE TEA FACTORY."],
  ["HUBBADA TEA PLANTATIONS P.LTD"],
  ["HIMALAYA TEA PLANTATIONS."],
  ["IBS TEA PLANTATIONS PVT. LIMITED"],
  ["IBEX LODGE TEA FACTORY"],
  ["JAE PPI AAR TEA INDUSTRIES"],
  ["JEYAM TEA PLANTATIONS"],
  ["KAIRBETTA ESTATE SYNDICATE"],
  ["KANMALAI TEA INDUSTRY."],
  ["KANNAVARAI TEA INDUSTRIES"],
  ["KARODAIYA TEA INDUSTRIES"],
  ["KIL-KOTAGIRI AND THIRUMBADY PLANTATIONS LTD."],
  ["KOKKALATTY TEA INDUSTRY."],
  ["KODANAD TEA ESTATE"],
  ["KOTAGIRI ESTATES TEA (A UNIT OF VGIL)"],
  ["KORAKUNDAH TEA FACTORY LTD"],
  ["KALLINGARA TEA FACTORY."],
  ["KONAVAKORAI TEA PLANTATIONS"],
  ["MACWOOD TEA COMPANY."],
  ["MADESWARA ESTATES TEA FACTORY P. LTD."],
  ["MADHAMALAI TEA PLANTATIONS P. LTD."],
  ["MAHARAJ TEA FACTORY PVT. LTD."],
  ["MANIKAL TEA FACTORY"],
  ["MAHALINGA TEA FACTORY."],
  ["MAVUKARAI (NILGIRI) EST. TEA FACTORY"],
  ["MEEDHANE TEA INDUSTRIES"],
  ["MOTHERLAND TEA PLANTATIONS P. LTD."],
  ["MANJUBETTA TEA FACTORY."],
  ["MADUPATTY TEA FACTORY"],
  ["MALLIAH TEA FACTORY."],
  ["MALAKIPARAI TEA ESTATE"],
  ["MANJOLAI TEA FACTORY."],
  ["NANKEMP TEA INDUSTRY"],
  ["NARIKKAL TEA FACTORY"],
  ["NARUMUKAI TEA COMPANY"],
  ["NATIONAL TEA PLANTATIONS P. LTD."],
  ["NEELAMALAI AGRO INDUSTRIES LTD."],
  ["NILGIRI AGRO AGENCIES PVT. LTD."],
  ["NILGIRI GLENBURN TEA LIMITED"],
  ["NILGIRI MONARCH TEA LIMITED"],
  ["NONSUCH TEA ESTATES LIMITED"],
  ["NILGIRI TEA ESTATES LIMITED"],
  ["NILGIRI TEA PRODUCING COMPANY"],
  ["NIRSAN PLANTATIONS"],
  ["NITON TEA COMPANY"],
  ["NAVILKAL ESTATE TEA FACTORY"],
  ["NEELAMALAI ENTERPRISES P.LTD"],
  ["NILGIRI MG TEA INDUSTRY"],
  ["NELLIYALAM TEA ESTATES LTD"],
  ["ORANGE VALLEY TEA INDUSTRY"],
  ["PANDIAN TEA INDUSTRIES"],
  ["PINEHILL TEA PLANTATIONS"],
  ["PARK VALLEY TEA PLANTATIONS"],
  ["PODDAR PLANTATION"],
  ["POWSLAND TEA FACTORY"],
  ["PRAMS TEA INDUSTRIES"],
  ["PREETHI TEA INDUSTRY PVT. LTD."],
  ["PONMALAI TEA FACTORY"],
  ["PRIYA TEA INDUSTRIES."],
  ["PANDIAR TEA FACTORY"],
  ["QUINSHOLA TEA FACTORY."],
  ["RAJA PLANTATIONS PVT. LIMITED"],
  ["RAJESWARI TEA INDUSTRIES."],
  ["RASI TEA INDUSTRY."],
  ["RICHMOUNT TEA INDUSTRIES."],
  ["RIVERSIDE TEA PLANTATION"],
  ["ROCK VALLEY TEA INDUSTRY P. LTD."],
  ["ROSE MOUNT TEA FACTORY PVT. LTD."],
  ["RIVERWOODS TEA PLANTATIONS"],
  ["ROYAL HERITAGE TEA PLANTATIONS"],
  ["RAJAMALAI TEA FACTORY"],
  ["RYAN TEA FACTORY"],
  ["ROSE VALLEY TEA INDUSTRIES."],
  ["ROBROY TEA FACTORY (GEEEMM TEA FACTORY)"],
  ["S. N. VALLEY TEA FACTORY"],
  ["SAMPATH TEA INDUSTRIES P. LTD."],
  ["SANTOSH TEA INDUSTRIES P. LTD."],
  ["SEETHAL TEA FACTORY."],
  ["SELVAGANAPATHY TEA INDUSTRIES."],
  ["SHANTHY TEA INDUSTRY."],
  ["SHARON TEA INDUSTRY."],
  ["SHYAMA SHYAM TEA INDUSTRY."],
  ["SILVER CLOUD ESTATE P. LTD."],
  ["SILVER VALLEY TEA INDUSTRIES"],
  ["SINGARA NILGIRI PLANTATION COMPANY."],
  ["SIVA & SIVA TEA FACTORY"],
  ["SIVA TEA PLANTATIONS."],
  ["SIVAKUMAR TEA INDUSTRIES"],
  ["SIVARAM TEA INDUSTRY."],
  ["SNOWDON TEA FACTORY"],
  ["SOGATHORAI VANI VILAS EST. COMPANY."],
  ["SOUTHERN TREE FARMS LIMITED"],
  ["SPRINGDALE COMPANY TEA FACTORY."],
  ["SREE DEVI TEA FACTORY P. LIMITED"],
  ["SREERAM TEA FACTORY."],
  ["SREE SAKTHI TEA INDUSTRY"],
  ["SREE SASTHA TEA FACTORY."],
  ["SREE VENKATESWARA TEA INDUSTRIES"],
  ["SRI BATHMA TEA INDSTRIES."],
  ["SRI IYYAN TEA INDUSTRIES."],
  ["SRI JADAYALINGA TEA FACTORY."],
  ["SRI SIVALINGA TEA INDUSTRIES."],
  ["SS TEA PLANTATIONS."],
  ["STANES AMALGAMATTED EST. PVT. LTD. (KOTADA)"],
  ["STERLING TEA FACTORY."],
  ["SHOLAYAR TEA FACTORY"],
  ["SVT TEA FACTORY"],
  ["STERLING TEA FACTORY"],
  ["SWAMY & SWAMY PLANTATIONS P. LTD."],
  ["SHARAVANA TEA INDUSTRIES."],
  ["SRI SUTHARSAN TEA INDUSTRY."],
  ["SUPRAJITH TEA FACTORY"],
  ["SMART TEA PLANTATIONS."],
  ["SATHYAM TEA INDUSTRIES"],
  ["SUSSEX TEA FACTORY"],
  ["TALAYAR TEA COMPANY LTD."],
  ["TC TEA & ENTERPRISES PVT. LTD."],
  ["THB TEA PLANTATIONS PVT.LTD."],
  ["THE BHAVANI TEA AND PRODUCE CO. LTD."],
  ["THE GNANAM TEA PLANTATIONS P. LTD."],
  ["THE PUTHUTOTAM ESTATES (1943) LTD."],
  ["THE WEST SIDE TEA COMPANY."],
  ["THIASHOLA PLANTATIONS PVT. LTD."],
  ["TOP HILL TEA FACTORY"],
  ["TUTTAPULLAM TEA & PRODUCE CO.P.LTD"],
  ["TIGERHILL TEA FACTORY."],
  ["TCS TEA INDUSTRIES."],
  ["UNITED PLANTERS ASSOCIATION OF SOUTHERN INDIA"],
  ["VELMURUGAN TEA PLANTATIONS"],
  ["VENKATARAMANA TEA FACTORY P. LTD."],
  ["VIGNESHWAR ESTATE TEA FACTORY"],
  ["VIJAYAKUMAR TEA INDUSTRIES"],
  ["VARUNI TEA COMPANY"],
  ["VENNIAR TEA FACTORY"],
  ["WOODBRIDGE TEA FACTORY"],
  ["WOODLAND TEA FACTORY."],
  ["WENTWORTH ESTATES."],
  ["YUVARAJ TEA MFG. COMPANY P. LTD."],
  ["YASODHAA TEA INDUSTRY."],
];

var factory = [
  ["1", "Kundah | Indco Tea Factory Ltd	"],
  ["2", "Karumbalam | Indco Tea Factory Ltd	"],
  ["3", "Mercunad | Indco Tea Factory Ltd	"],
  ["4", "Mahalinga | Indco Tea Factory Ltd	"],
  ["5", "Manjoor | Indco Tea Factory Ltd	"],
  ["6", "Ithalar | Indco Tea Factory Ltd	"],
  ["7", "Pandalur | Indco Tea Factory Ltd	"],
  ["8", "Kaikatty | Indco Tea Factory Ltd	"],
  ["9", "Kattabettu | Indco Tea Factory Ltd	"],
  ["10", "Salisbury | Indco Tea Factory Ltd	"],
  ["11", "Frontier | Indco Tea Factory Ltd	"],
  ["12", "Kinnakorai | Indco Tea Factory Ltd	"],
  ["13", "Bikkatty | Indco Tea Factory Ltd	"],
  ["14", "Ebbanad | Indco Tea Factory Ltd	"],
  ["15", "Bitherkad | Indco Tea Factory Ltd	"],
  ["16", "Nanjanad | Indco Tea Factory Ltd	"],
];

var auction = [
  ["1", "04-03-2020", "do", "14-03-2020", "do", "15-03-2020", "do"],
  ["2", "11-03-2020", "do", "21-03-2020", "do", "22-03-2020", "THURSDAY"],
  ["3", "18-03-2020", "do", "28-03-2020", "FRIDAY", "29-03-2020", "do"],
  ["4", "25-03-2020", "do", "04-04-2020", "do", "05-04-2020", "do"],
  ["5", "01-04-2020", "do", "11-04-2020", "do", "12-04-2020", "do"],
  ["6", "08-04-2020", "do", "18-04-2020", "THURSDAY", "18-04-2020", "THURSDAY"],
];

var holiday = [
  ["01-01-2020", "Tuesday New Year Day"],
  ["15-01-2020", "Tuesday Pongal"],
  ["26-01-2020", "Saturday Republic Day"],
  ["14-04-2020", "Sunday Tamil New Year"],
  ["19-04-2020", "Friday Good Friday"],
  ["01-05-2020", "Wednesday May Day"],
  ["05-06-2020", "Wednesday Ramzan"],
  ["15-08-2020", "Thursday Independence Day"],
  ["02-09-2020", "Monday Vinayakar Chathurthi"],
  ["02-10-2020", "Wednesday Gandhi Jayanthi"],
  ["07-10-2020", "Monday Ayutha Pooja"],
  ["27-10-2020", "Sunday Deepavali"],
  ["25-12-2020", "Wednesday Christmas Day"],
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  websiteDate,
  website1,
  websiteDate1,
  website2,
  websiteDate2,
  bugsDate,
  member,
  members,
  factory,
  auction,
  holiday,
};
