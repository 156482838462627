import axios from "axios";

class Api {
  //  url = process.env.PUBLIC_URL+"/svr/tlts.php";
  // url = "http://localhost/indcoserve-react/svr/data-service.php";
  // imgurl = "http://localhost/indcoserve-react/";

  url = "https://grower.indcoserve.broadline-beta.com/svr/data-service.php";
  imgurl = "https://grower.indcoserve.broadline-beta.com/";

  apiCall(type, data) {
    let respond = axios({
      method: "POST",
      url: this.url + "?" + type + "=true",
      data: data,
      config: { headers: { "Content-Type": "application/json" } },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 404) {
            return `\u2014`;
          }
          if (error.response.status === 500) {
            return `\u2014`;
          }
        }
      });

    return respond;
  }

  apiCallWithFile(type, data) {
    let respond = axios({
      method: "POST",
      url: this.url + "?" + type + "=true",
      data: data,
      config: {
        headers: {
          "Content-Type": "multipart/form-data,application/json",
        },
      },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 404) {
            return `\u2014`;
          }
          if (error.response.status === 500) {
            return `\u2014`;
          }
        }
      });

    return respond;
  }

  apiCallGet(type) {
    let responds = axios({
      method: "POST",
      url: this.url + "?" + type + "=true",
      config: {
        headers: { "Content-Type": "application/pdf" },
        // headers: { "Content-Type": "application/json", Authorization: "sadad" },
      },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 404) {
            return `\u2014`;
          }
          if (error.response.status === 500) {
            return `\u2014`;
          }
        }
      });

    return responds;
  }

  apiCallGetUrl(url) {
    let responds = axios({
      method: "GET",
      url: "../" + url,
      responseType: "blob",
      config: {
        headers: { "Content-Type": "application/pdf" },
        headers: { "Access-Control-Allow-Origin": "*" },
        headers: {
          "Access-Control-Allow-Headers": "Origin,Content-Type, authorization",
        },
      },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 404) {
            return `\u2014`;
          }
          if (error.response.status === 500) {
            return `\u2014`;
          }
        }
      });

    return responds;
  }
}

export default Api;
