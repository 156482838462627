import Dashboard from "@material-ui/icons/Dashboard";
import {
  // Receipt,
  People,
  FileCopy,
  Person,
} from "@material-ui/icons";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import ContantManagement from "views/contnt-management/contant-management";
import Ceopage from "views/Ceo/Ceo.js";
import Upload from "views/Upload/Upload.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
// import Auction from "views/Auction/Auction.js";
import Price from "views/Price/Price.js";
import Post from "views/Price/Post.js";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
import Master from "views/Master/Master.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
import BackupIcon from "@material-ui/icons/Backup";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/contant-maintance",
    name: "Contant",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ContantManagement,
    layout: "/admin",
  },
  {
    path: "/ceo",
    name: "CEO Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Ceopage,
    layout: "/admin",
  },
  {
    path: "/masters",
    name: "Masters",
    rtlName: "قائمة الجدول",
    icon: FileCopy,
    component: Master,
    layout: "/admin",
  },
  // {
  //   path: "/auction",
  //   name: "Auction",
  //   rtlName: "قائمة الجدول",
  //   icon: Receipt,
  //   component: Auction,
  //   layout: "/admin",
  // },

  {
    path: "/uploads",
    name: "Data Upload",
    rtlName: "لوحة القيادة",
    icon: BackupIcon,
    component: Upload,
    layout: "/admin",
  },
  {
    path: "/post",
    name: "Post",
    rtlName: "قائمة الجدول",
    icon: FileCopy,
    component: Post,
    layout: "/admin",
  },
  {
    path: "/price",
    name: "Price",
    rtlName: "قائمة الجدول",
    icon: LocalOfferIcon,
    component: Price,
    layout: "/admin",
  },
  {
    path: "/members",
    name: "Members",
    rtlName: "قائمة الجدول",
    icon: People,
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },

  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
