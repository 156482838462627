import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles(styles);

export default function TasksMasters(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { tasks, rtlActive, title } = props;
  const tableCellClasses = classnames(classes.tableCell, {
    [classes.tableCellRTL]: rtlActive,
  });
  return (
    <div>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={tableCellClasses}></TableCell>
            <TableCell className={tableCellClasses}></TableCell>
            <TableCell className={tableCellClasses}></TableCell>
            <TableCell className={tableCellClasses}></TableCell>

            <TableCell align="center" className={classes.tableActions}>
              <Tooltip
                id="tooltip-top-bottom"
                title="Add"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Add"
                  className={classes.tableActionButton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
          {tasks.map((value, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell className={tableCellClasses}>{index + 1}</TableCell>
              <TableCell className={tableCellClasses}>{value[1]}</TableCell>
              <TableCell className={tableCellClasses}>{value[2]}</TableCell>

              {title === "Users" ? (
                <TableCell className={tableCellClasses}>
                  {"Testing" + value[0] + "@in"}
                </TableCell>
              ) : (
                <TableCell className={tableCellClasses}></TableCell>
              )}
              <TableCell className={classes.tableActions}>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={handleClickOpen}
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top-start"
                  title="Remove"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Close"
                    className={classes.tableActionButton}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.close
                      }
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We will send updates
                occasionally.
              </DialogContentText> */}
          {title === "Members" ? (
            <div>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
              >
                <MenuItem value={10}>Seller</MenuItem>
                <MenuItem value={20}>Buyer</MenuItem>
                <MenuItem value={30}>Broker</MenuItem>
              </Select>
            </div>
          ) : (
            ""
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={title + " Name"}
            rows={2}
            type="text"
            fullWidth
          />
          {title === "Users" ? (
            <TextField
              autoFocus
              margin="dense"
              id="Email"
              label="Email"
              type="email"
              fullWidth
            />
          ) : (
            ""
          )}
          <TextField
            autoFocus
            margin="dense"
            id="Address"
            label="Address"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TasksMasters.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.node),
  rtlActive: PropTypes.bool,
};
