import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import GetAppIcon from "@material-ui/icons/GetApp";
import Success from "components/Typography/Success.js";
// import Warning from "components/Typography/Warning.js";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import { Update } from "@material-ui/icons";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Info from "components/Typography/Info.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import xlsx from "assets/files/members-supplies.xlsx";
import xlsx2 from "assets/files/price.xlsx";
// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
const useStyles = makeStyles(styles);

export default function Upload() {
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <GroupOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Tea Supplies</p>
              <h3 className={classes.cardTitle}>
                3200 <small>Kg</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Info>
                  <GetAppIcon />
                </Info>
                <a href={xlsx}>Download (Xlsx) format member supplies</a>
              </div>
              <input
                accept="image/*"
                className={classes.input}
                id="supplie-upload"
                multiple
                type="file"
                style={{ display: "none" }}
              />
              <label htmlFor="supplie-upload">
                <Button
                  component="span"
                  size="small"
                  variant="outlined"
                  color="default"
                >
                  Upload
                </Button>
              </label>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>eco</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Tea Price</p>
              <h3 className={classes.cardTitle}> ₹ 125.93</h3>
            </CardHeader>
            <CardFooter stats>
              {/* <DateRange /> */}
              <div className={classes.stats}>
                <Success>
                  <GetAppIcon />
                  <a href={xlsx2}>Download (Xlsx) format Price</a>
                </Success>
              </div>
              <input
                accept="image/*"
                className={classes.input}
                id="price-upload"
                multiple
                type="file"
                style={{ display: "none" }}
              />
              <label htmlFor="price-upload">
                <Button
                  component="span"
                  size="small"
                  variant="outlined"
                  color="default"
                >
                  Upload
                </Button>
              </label>
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fixed Issues</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Followers</p>
              <h3 className={classes.cardTitle}>+245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
