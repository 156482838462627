import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import moment from "moment";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import Success from "components/Typography/Success.js";

import DataService from "variables/data.js";

const useStyles = makeStyles(styles);

export default function Tasks(props) {
  let data = new DataService();
  const { tasks, rtlActive, title, taskshandle } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let [form, setForm] = React.useState({
    id: "",
    discription: "",
    tamil_cnt: "",
    title: "",
    ndate: "",
    ntype: "",
  });
  // const [message, setMessage] = React.useState({});
  // const [submit, isSubmit] = React.useState(false);

  // const handleSubmit = () => {
  //   isSubmit(true);
  // };

  // const handleSubmitClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   isSubmit(false);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (form.discription !== "" && form.title !== "") {
      data
        .saveNotification(form)
        // .then((res) => res.json())
        .then((result) => {
          // setMessage(result);
          setOpen(false);
          taskshandle();
          // handleSubmit();
        });
      // console.log(message);
    }
  };

  const updateState = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
    console.log(form);
  };

  // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  // const [checked, setChecked] = React.useState([...props.checkedIndexes]);
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  // console.log(form);
  const tableCellClasses = classnames(classes.tableCell, {
    [classes.tableCellRTL]: rtlActive,
  });
  return (
    <div>
      <Table className={classes.table}>
        <TableBody>
          {title !== "About" ? (
            <TableRow>
              <TableCell className={tableCellClasses}></TableCell>
              <TableCell className={tableCellClasses}></TableCell>
              <TableCell className={tableCellClasses}></TableCell>
              <TableCell className={tableCellClasses}></TableCell>
              <TableCell align="center" className={classes.tableActions}>
                <Tooltip
                  id="tooltip-top-bottom"
                  title="Add"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Add"
                    className={classes.tableActionButton}
                    onClick={() => {
                      setForm({
                        id: "",
                        discription: "",
                        ndate: "",
                        title: "",
                        tamil_cnt: "",
                        ntype: title,
                      });
                      setOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow></TableRow>
          )}
          {tasks.map((value) => (
            <TableRow key={value.id} className={classes.tableRow}>
              <TableCell className={tableCellClasses}>
                {/* <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                onClick={() => handleToggle(value)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              /> */}
              </TableCell>
              <TableCell className={tableCellClasses}>{value.ndate}</TableCell>
              <TableCell className={tableCellClasses}>
                {value.discription}
              </TableCell>
              <TableCell className={tableCellClasses}>
                {value.tamil_cnt}
              </TableCell>
              <TableCell className={classes.tableActions}>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={() => {
                      setForm(value);
                      setOpen(true);
                    }}
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField
            id="ndate"
            name="ndate"
            label="Date"
            type="date"
            value={moment(form.ndate).format("YYYY-MM-DD")}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={updateState}
          />
          <TextField
            autoFocus
            margin="dense"
            id="title"
            name="title"
            label={title + " Title"}
            type="text"
            value={form.title || ""}
            fullWidth
            onChange={updateState}
          />
          <TextField
            autoFocus
            margin="dense"
            id="discription"
            name="discription"
            label={title + " English"}
            rows={3}
            multiline
            type="text"
            value={form.discription}
            fullWidth
            onChange={updateState}
          />
          <TextField
            autoFocus
            margin="dense"
            id="tamil_cnt"
            name="tamil_cnt"
            label={title + " Tamil Contant"}
            rows={3}
            multiline
            type="text"
            value={form.tamil_cnt || null}
            fullWidth
            onChange={updateState}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup Snackbar */}

      {/* <Snackbar
        open={submit}
        autoHideDuration={6000}
        onClose={handleSubmitClose}
      >
        <Success>{message.message}</Success>
      </Snackbar> */}
    </div>
  );
}

Tasks.propTypes = {
  // tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.object),
  taskshandle: PropTypes.func,
  rtlActive: PropTypes.bool,
  // checkedIndexes: PropTypes.array,
};
