import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import xlsx from "assets/files/members-supplies.xlsx";
// import PriceTable from "./PriceTable.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Typography from "@material-ui/core/Typography";
import DataService from "variables/data.js";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import ThumbnailGallery from "react-thumbnail-gallery";
// import ReactPlayer from "react-player";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Post() {
  const classes = useStyles();
  let [posts, setPosts] = React.useState({});
  let data = new DataService();

  React.useEffect(() => {
    data.getPost().then((result) => {
      console.log(result);
      setPosts(result);
    });
  }, []);

  // let [items, setItems] = React.useState([]);
  // let [item, setItem] = React.useState({
  //   id: "", // *string* A unique string identifier for this item.
  //   title: "", // *string* The item's title.
  //   description: "", // *string* The item's description.
  //   altText: "", // *string* The item's alt text.
  //   src: "", // *string* The item's URL.
  //   height: "", // *number* The item's height.
  //   width: "", // *number* The item's width.
  //   thumbSrc: "", // *string* The item's thumbnail URL.
  //   thumbHeight: "", // *number* The item's thumbnail height.
  //   thumbWidth: "", // *number* The item's thumbnail width.
  // });

  // let [item, setItem] = React.useState({
  //   type: "",
  //   url: "",
  // });
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = (e, row) => {
  //   row.items.map((r, i) => {
  //     console.log(r);
  //     let url = "";
  //     if (r.fileformat === "1") {
  //       url = r.filelink;
  //     } else {
  //       // const urls = `${process.env.PUBLIC_URL}${r.storage_name}`;
  //       data.getfiles(r.storage_name).then((res) => {
  //         console.log(res);
  //         // let urls = URL.createObjectURL(res);
  //         // url = urls;
  //       });
  //     }

  //     setItem({
  //       type: r.fileformat,
  //       url: url,
  //     });
  //     let it = [...items];
  //     it.concat(item);
  //     setItems(it);
  //   });

  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <h4 className={classes.cardTitleWhite}>CEO Posts</h4>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">POST DATE</TableCell>
                  <TableCell align="center">TITLE</TableCell>
                  <TableCell align="center">DISCRIPTION</TableCell>
                  <TableCell align="center">TAMIL DISCRIPTION</TableCell>
                  {/* <TableCell align="center">ACTION</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.length > 0 ? (
                  posts.map((row, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">{row.ndate}</TableCell>
                        <TableCell align="center">{row.title}</TableCell>
                        <TableCell align="center">{row.discription}</TableCell>
                        <TableCell align="center">{row.tamil_cnt}</TableCell>
                        {/* <TableCell align="center">
                          <label htmlFor="icon-button-file">
                            <Icon
                              color="primary"
                              aria-label="Preview "
                              component="span"
                              onClick={(e) => handleClickOpen(e, row)}
                            >
                              visibility
                            </Icon>
                          </label>
                        </TableCell> */}
                        {/* <TableCell align="center">ACTION</TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="4" align="center">
                      No data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          {/* {videoUrl ? (
            <ReactPlayer url={videoUrl} />
          ) : (
            <object
              data={fileUrls}
              width="100%"
              height="550px"
              alt="img"
            ></object>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
