import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  // Button as MatButton,
  MenuItem,
  // IconButton,
  Icon,
  FormControl,
  FormGroup,
} from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";

// import YouTube from "react-youtube";
// import { DeleteIcon, AddIcon, VisibilityIcon } from "@material-ui/icons";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactPlayer from "react-player";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import xlsx from "assets/files/members-supplies.xlsx";
// import { useTheme } from "@material-ui/core/styles";

// import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.js";
import DataService from "variables/data.js";

const styles = {
  table: {
    minWidth: 650,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  heightDialog: {
    height: "700px",
  },
};

const useStyles = makeStyles(styles);

export default function Ceopage() {
  const classes = useStyles();
  // const theme = useTheme();
  let data = new DataService();
  // let [filecollection, setFileCollection] = React.useState([]);
  let history = useHistory();

  let [file, setFile] = React.useState([]);
  let [form, setForm] = React.useState({
    title: "",
    discrpt: "",
    tamil_cnt: "",
    fileArray: [],
  });
  // let [disabled, SetDisable] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");
  // const [message, setMessage] = React.useState({});
  const [fileUrls, setfileUrl] = React.useState("");

  const handleClickOpen = (e, i) => {
    // if(e[i]){
    if (file[i].link) {
      setVideoUrl(file[i].link);
    }

    setOpen(true);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    form[name] = value;
    // console.log(e.target.name);

    setForm(form);
  };

  const handleClose = () => {
    setVideoUrl(null);
    setfileUrl(null);
    setOpen(false);
  };

  console.log(file);
  const addProgram = () => {
    let fileData = { fileformate: "", file: [], fileUrl: "", link: "" };

    let files = file.concat(fileData);
    setFile(files);
  };

  const handleDelete = (e, i) => {
    e.preventDefault();
    let filesdelete = [...file];
    console.log(i);
    if (i !== -1) {
      filesdelete.splice(i, 1);
      setFile(filesdelete);
    }
  };

  const handleSave = (e, i) => {
    e.preventDefault();
    if (form.title === "") {
      return;
    }
    if (form.discrpt === "") {
      return;
    }
    form.fileArray = file;
    let length = form.fileArray.length;
    if (
      length > 0 &&
      form.fileArray[length - 1].fileformate !== "" &&
      form.fileArray[length - 1].fileformate !== 0
    ) {
      console.log(form);
      let f =
        form.fileArray[length - 1].fileformate === 1
          ? form.fileArray[length - 1].link !== ""
            ? true
            : false
          : form.fileArray[length - 1].file.length !== 0
          ? true
          : false;
      console.log(f);
      if (f) {
        data.savePost(form).then((result) => {
          // setMessage(result);
          if (result.message.message === "Record saved successfully.") {
            // setForm({ title: "", discrpt: "", fileArray: [] });
            // setFile({ fileformate: "", file: [], fileUrl: "", link: "" });
            history.push("/admin/post");
            console.log(result);
          }
        });
      }
      return;
    }
  };
  // const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleText = (e, i) => {
    e.preventDefault();
    let programs = [...file];
    let name = e.target.name;
    // let fc = [...filecollection];

    if (name === "fileformate") {
      if (e.target.value === 1) {
        programs[i]["file"] = [];
        // filecollection[i]["file"] = [];
        // SetDisable(true);
      }

      if (e.target.value === 2) {
        // if (e.target.name === "file") {
        //   console.log(e.target.files[0]);
        //   let c = { docitems: [] };
        //   let filecollect = filecollection.concat(c);
        //   setFileCollection(filecollect);
        //   fc[i]["docitems"] = e.target.files[0];
        //   setFileCollection(fc);
        //   console.log(filecollect);
        // }
        programs[i]["link"] = "";
        // SetDisable(false);
      }
    }

    if (e.target.name === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      // console.log(url);
      programs[i]["fileUrl"] = url;
      setfileUrl(url);
      console.log(e.target.files[0]);
      programs[i][name] = e.target.files[0];
    } else {
      programs[i][name] = e.target.value;
    }
    setFile(programs);
    // console.log(filecollection);
  };

  const fileformate = [
    {
      value: 0,
      label: "....Choose....",
    },
    {
      value: 1,
      label: "Videos",
    },
    {
      value: 2,
      label: "Docunments & Images",
    },
  ];

  // const opts = {
  //   height: "390",
  //   width: "640",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1,
  //   },
  // };

  // const _onReady = (event) => {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo();
  // };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <FormGroup>
          <Card>
            <CardHeader color="success">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <h4 className={classes.cardTitleWhite}>CEO Posts</h4>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    onChange={handleChange}
                    label="Title"
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="discrpt"
                    label="Description"
                    name="discrpt"
                    onChange={handleChange}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required
                    id="tamil_cnt"
                    label="Tamil Description"
                    name="tamil_cnt"
                    onChange={handleChange}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan="3"></TableCell>
                        <TableCell align="center">
                          {/* <Button onClick={addProgram} variant="contained">
                          Add
                        </Button> */}
                          <label htmlFor="icon-button-file">
                            <Icon
                              aria-label="Add Item"
                              color="primary"
                              component="span"
                              onClick={addProgram}
                            >
                              add
                            </Icon>
                          </label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>SI.No</TableCell>
                        <TableCell align="center">File Type</TableCell>
                        <TableCell align="center">
                          Upload File (or) File Link{" "}
                        </TableCell>
                        {/* <TableCell align="center">File Link</TableCell> */}
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {file.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {i + 1}
                          </TableCell>
                          <TableCell align="center">
                            <FormControl>
                              <TextField
                                autoFocus
                                id="fileformate"
                                name="fileformate"
                                label={"File Type"}
                                onChange={(event) => handleText(event, i)}
                                rows={2}
                                defaultValue={0}
                                select
                                fullWidth
                              >
                                {fileformate.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </TableCell>

                          <TableCell align="center">
                            {row.fileformate === 1 || row.fileformate === "" ? (
                              <FormControl>
                                <TextField
                                  autoFocus
                                  id="link"
                                  name="link"
                                  label={"File Link"}
                                  onChange={(e) => handleText(e, i)}
                                  type="text"
                                  fullWidth
                                />
                              </FormControl>
                            ) : (
                              <FormControl>
                                <TextField
                                  id="file"
                                  name="file"
                                  label={"File"}
                                  onChange={(e) => handleText(e, i)}
                                  type="file"
                                  fullWidth
                                />
                              </FormControl>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <label htmlFor="icon-button-file">
                              <Icon
                                color="error"
                                aria-label="Preview "
                                component="span"
                                onClick={(e) => handleClickOpen(e, i)}
                              >
                                visibility
                              </Icon>
                            </label>
                            &nbsp; &nbsp;
                            <label htmlFor="icon-button-file">
                              <Icon
                                color="secondary"
                                aria-label="Delete"
                                component="span"
                                onClick={(e) => handleDelete(e, i)}
                              >
                                delete
                              </Icon>
                            </label>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleSave} type="submit" color="success">
                Save
              </Button>
            </CardFooter>
          </Card>
        </FormGroup>
      </GridItem>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        className={classes.heightDialog}
        // fullScreen
        // scroll="body"/
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <label htmlFor="icon-button-file">
            <Icon
              color="error"
              aria-label="Preview "
              component="span"
              onClick={handleClose}
            >
              arrow_back
            </Icon>
          </label>
          {/* <Button variant="contained" onClick={handleClose} color="secondary">
            Back
          </Button> */}
          &nbsp; Preview
        </DialogTitle>

        <DialogContent>
          {videoUrl ? (
            <ReactPlayer url={videoUrl} />
          ) : fileUrls ? (
            <object
              data={fileUrls}
              width="100%"
              height="550px"
              alt="img"
            ></object>
          ) : (
            // <YouTube videoId={videoUrl} opts={opts} onReady={_onReady} />
            <Alert severity="error">No Video or File Url Founded</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="danger">
            Back
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Update
          </Button> */}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
