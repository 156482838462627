import React from "react";
import Api from "services/api.js";

export default class DataService extends React.Component {
  constructor(props) {
    super(props);

    this.state = { time: "" };
    this.api = new Api();
  }

  // componentDidCatch() {
  // let time = this.api.apiCallGet("getServerTime");
  // time
  //   .then((result) => {
  //     this.setState({ time: result });
  //     // console.log(result);
  //     //   return result;
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //     return Promise.reject(error);
  //   });
  // }

  time = () => {
    return this.api.apiCallGet("getServerTime");
  };

  getNotification = () => {
    return this.api.apiCallGet("getNotification");
  };

  saveNotification = (data) => {
    return this.api.apiCall("saveNotification", data);
  };

  savePost = (data, files) => {
    let formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    data.fileArray.forEach((element, i) => {
      formdata.append("files" + i, element.file);
    });
    return this.api.apiCallWithFile("savePost", formdata);
  };

  login = (data) => {
    return this.api.apiCall("login", data);
  };

  getPost = () => {
    return this.api.apiCallGet("getPost");
  };

  getfiles = (url) => {
    return this.api.apiCallGetUrl(url);
  };
}
